import React from 'react';
import SEO from '../components/SEO';

export default function HomePage() {
  return (
    <>
      <SEO title="Start" />
      <div className="center main">
        <h2>Wir wünschen eine schöne</h2>
        <h1>Adventszeit!</h1>
        <br />
        <br />
        <p>
          Für dieses Jahr werden leider keine Bestellungen mehr entgegengenommen. 
        </p>
         {/*<p>
          Neuheit: Dieses Jahr kann man sich auch Türkränze gestalten.
        </p> */}
        <p>Wir bedanken uns für alle Bestellungen!</p>
        <p>Ihr Wurzelkinder-Waldkindergarten Pleidelsheim</p>
        <br />

        {/* <h1>Handgebundene Adventskränze</h1>
        <h2>Novembercafe Pleidelsheim</h2>
        <h2>Kuchen zum mitnehmen</h2>
        <br />
        <br />
        <h2>22.11.2020</h2>
        <h2>12:00 - 16:00 Uhr</h2>
        <h2>Foyer der Sporthalle Ludwig-Hofer Straße 7, Pleidelsheim</h2>
        <br />
        <Link to="/bestellung" className="mark bigFont">
          Vorbestellung
        </Link>
        <br />
        <br />
        <h2>Online vorbestellen, solange Vorrat reicht! </h2>
        <h2>Die Wurzelkinder freuen sich auf Ihren Besuch.</h2> 
        Momentan kann das Fest unter Einhaltung der 3G Regelung stattfinden.
          Bitte erkundigen Sie sich am Tag des Festes über die genauen Corona
          Regelungen.*/}
        <br />
      </div>
    </>
  );
}
